<template>
  <div id="sign-up-page">

    <SignUp />

  </div>
</template>

<script>
import SignUp from '@/components/settings/SignUp.vue'

export default {
  name: 'SignUpPage',
  components: {
    SignUp,
  }
}
</script>
