<template>
  <div id="sign-up" class="big-m-t big-m-b d-f jc-c p">
    <div class="w-100">
      <form class="d-f fd-c ai-c m-b-children">
        <p class="tt-up">Sign Up</p>    

        <!-- <div class="input-container w-100">
          <label class="label" for="invite">Invite Code</label>
          <input type="invite" id="invite" name="invite" v-model="invite" placeholder="Invite Code"/>
        </div> -->


        <div class="d-f jc-sb w-100">
          <div class="input-container w-100">
            <label class="label" for="invite">Invite Code</label>
            <input type="invite" id="invite" name="invite" v-model="invite" placeholder="Invite Code"/>
          </div>
          <div class="m-l d-f jc-c">
            <a class="ws-pre button" href="https://form.typeform.com/to/MrNJchf4" target="_blank">
              <span>Get invite</span>
            </a>
          </div>
        </div>

        <div class="input-container w-100">
          <label class="label" for="email">Email</label>
          <input type="email" id="email" name="email" v-model="email" placeholder="Email"/>
        </div>
        <div class="input-container w-100">
          <label class="label" for="username">Username</label>
          <input type="text" id="username" for="username" v-model="username" placeholder="Username"/>
        </div>
        <div class="input-container w-100">
          <label class="label" for="password">Password</label>
          <input type="password" id="password" name="password" v-model="password" placeholder="Password"/>
        </div>
        <div class="input-container w-100">
          <label class="label" for="confirm-password">Confirm Password</label>
          <input type="password" id="confirm-password" name="confirm-password" v-model="confirmPassword" placeholder="Confirm Password"/>
        </div>
        <div class="d-f jc-c ai-c">
          <!-- <input class="w-auto m-r" type="checkbox" v-model="termsAccepted"> -->
          <label class="container w-auto m-r">
            <input type="checkbox" v-model="termsAccepted">
            <span class="checkmark"></span>
          </label>
          <div class="">
            I accept Algorhythm 
            <router-link to="/legal" target="_blank" class="text-link underline">Terms</router-link>
            and
            <router-link to="/legal" target="_blank" class="text-link underline">Privacy</router-link>
            conditions.
          </div>
        </div>
        <button type="submit" @click.prevent="register">Sign Up</button>
        <router-link class="tt-up font-small" to="/sign_in">Sign In</router-link>
      </form>
    </div>

  </div>
</template>

<script>
import { auth, rtdb } from '@/db.js'
import { mapActions } from "vuex"

export default {
  name: 'RegisterComp',
  data() {
    return {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        termsAccepted: false,
        invite: ''
    }
  },
  mounted () {

  },
  methods: {
    register () {
      if (!this.username ||!this.email || !this.password) {
        alert('Empty fields')
      }
      else if (this.password != this.confirmPassword) {
        alert(`Passwords doesn't match`)
      }
      else if (!this.termsAccepted) {
        alert(`You have to accept our terms and privacy conditions to register`)
      }
      else {
        rtdb.ref('invites').child(this.invite).once('value').then((snap)=>{
            console.log(snap.exists());
            if ( !snap.exists() ) {
              alert("This invite code does not exist")
            } else { 
              const usernamesRef = rtdb.ref('usernames')
              const encodedUsername = encodeURIComponent(this.username).replace(/\./g, '%2E')
              usernamesRef.child(encodedUsername).once('value').then((snap)=>{
                if ( snap.exists() ) {
                    alert("username already exists")
                } else {
                    auth
                    .createUserWithEmailAndPassword(this.email, this.password)
                    .then((authData) => {
                      const usersRef = rtdb.ref().child('userSettings')
                      const usernamesRef = rtdb.ref('usernames')
                      usersRef.once('value').then((snap) => {
                        if ( snap.numChildren() ) {
                          var countRef = snap.numChildren()
                        } else {
                          var countRef = 0
                        }

                        const settings = {
                            'member': countRef,
                            'username': this.username,
                            'usernameUrl': encodedUsername,
                            'locations': {
                              'loc0': 'World'
                            },
                            'genres': {
                              'gen0': 'All'
                            },
                            'locationInfo': {
                              'placeRef': 'X'
                            }
                        }
                        usersRef.update({
                          [authData.user.uid]: settings
                        }).then().catch(err => {alert(err.message)})
                        this.setUserSettings(settings)

                        usernamesRef.update({
                          [encodedUsername]: authData.user.uid
                        }).then().catch(err => {alert(err.message)})

                        rtdb.ref('invites').child(this.invite).update({
                          [authData.user.uid]: 'registered'
                        }).then().catch(err => {alert(err.message)})

                      }).then(() => {
                        console.log('User created')
                        this.$router.push('/settings')
                        // this.$router.push('/' + encodedUsername)
                      }).catch(err => {alert(err.message)})
                    }).catch(err => {alert(err.message)})

                }
              }).catch(err => {
                alert(err.message)
                console.log(err.message)
              })

            }
        })

        // auth
        // .createUserWithEmailAndPassword(this.email, this.password).then((authData) => {
        //     const usersRef = rtdb.ref().child('userSettings')
        //     const usernamesRef = rtdb.ref('usernames')
        //     usersRef.once('value').then((snap) => {
        //       const countRef = snap.numChildren()
        //       const username = 'Member' + countRef
        //       usersRef.update({
        //         [authData.user.uid]: {
        //           'member': countRef,
        //           'username': username
        //         }
        //       })
        //       usernamesRef.update({
        //         [username]: authData.user.uid
        //       })
        //     }).then(() => {
        //       console.log('User created')
        //       // this.$router.go()
        //     })
        // }).catch(err => {
        //     alert(err.message)
        //     console.log(err.message)
        // })
      }
    },

    ...mapActions({
      setUserSettings: 'setUserSettings',
    }),

  }

}
</script>

<style lang="scss" scoped>

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 16px;
  margin-bottom: 18px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid grey;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: -1px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

</style>

